import React from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
//import  "react-datepicker/lib/index";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import TimeSlider from "./TimeSlider";

export default class ZapisForm extends React.Component
{
    constructor()
    {
        super(...arguments);

        this.state = {
            step: 1,
            format: 2,
            specialitiesLoading: true,
            doctorsLoading: false,
            freeTimeLoading: false,
            datesLoading: false,
            error: false,
            specialities: [],
            speciality: false,
            doctors: [],
            doctor: false,
            docInfo: "",
            date: false,
            time: 0,
            times: [],
            isOpen: false,
            endpoint: "https://api.medeor74.ru/",
            name: '',
            lastname: '',
            secondname: '',
            phone: '8',
            birthdate: '',
            dms: false,
            isOnline: false,
            sendLoading: false,
            sendSuccess: false,
            sendError: false,
            check: true,
            cancelSuccess: false,
            serviceLoading: false,
            selectedDay: '',
            selectedTime: '',
            services: [],
            service: 0,
            preparation: "",
            docId: "",
            showMobileAppInfo: true,
            tip: "",
            progress: '0%',
            highlightDates: [{ "react-datepicker__day--highlighted": [
                    new Date()
                ]
            }],
            //dates: [ new Date() ],
            dates: [],
            scheduleLoading: false
        };
    }

    async componentDidMount() {
        await this.getSpecialities();
        await this.splitSearchParams();
    }

    splitSearchParams = async () => {
        let params = {};
        let s = window.location.search;

        if(s) s = s.substring(1);

        let arr = s ? s.split("&") : [];

        arr.forEach( item => {
            let a = item.split("=");
            params[a[0]] = a[1];
        });

        //console.log(params);

        if(params && params.speciality) {

            let selectedDoctor = 0;
            let selectedService = 0;

            try {
                await this.getDoctors(params.speciality);

                if(params && params.speciality && params.doctor) {
                    this.state.doctors.forEach( doc => {
                        if(doc && doc['Врач'] === params.doctor){
                            selectedDoctor = params.doctor;
                        }
                    });

                    await this.getServices(params.doctor);
                }

                if(params && params.speciality && params.doctor && params.service) {

                    this.state.services.forEach( srv => {
                        if(srv && srv['Номенклатура'] === params.service){
                            selectedService = srv;
                        }
                    });
                }

                //console.log(selectedService);

                if(selectedService){

                    this.setState({
                        error: false,
                        speciality: params.speciality,
                        docInfo: "",
                        doctor: selectedDoctor,
                        service: selectedService['Номенклатура'],
                        price: selectedService['Цена'],
                        preparation: selectedService['Подготовка'],
                        isOnline: selectedService['Онлайн'],
                        datesLoading: true,
                        dates: [],
                        serviceLoading: false
                    });

                    this.getFreeTime( selectedService['Номенклатура'], selectedDoctor);
                }
                else {
                    this.setState({
                        speciality: params.speciality,
                        docInfo: "",
                        doctor: selectedDoctor,
                        service: selectedService,
                        preparation: ""
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    };

    getSpecialities = async () => {
        let currentDate = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        if(month < 10) month = "0" + month;

        let date = new Date().getDate();
        if(date < 10) date = "0" + date;

        currentDate = currentDate + "-" + month + "-" + date;

        this.setState({
            specialitiesLoading: true,
            currentDate: currentDate
        });

        try {
            const r = await axios(this.state.endpoint + "getSpecs/");
            if(r && r.data){
                this.setState({
                    error: false,
                    specialities: r.data,
                    specialitiesLoading: false
                });
            }
        }
        catch (e) {
            console.log(e);
        }

        /*axios({
            method: 'get',
            url: this.state.endpoint + "getSpecs/"
        }).then((response) => {
            let { data } = response;

            this.setState({
                error: false,
                specialities: data,
                specialitiesLoading: false
            });
        });*/
    };

    onSpecChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if(value != 0) this.getDoctors(value);

        this.setState({
            [name]: value,
            docInfo: "",
            doctor: 0,
            service: 0,
            preparation: ""
        });
    };

    onFormatChange = (event) => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if(value === 0){
            this.setState({
                step: 1,
                format: 0
            });
        }

        if(value === 1){

            let data = [
                {
                    "Врач": "5eaef8b5-d13b-11e5-80c0-0cc47a0dc212",
                    "Фамилия": "Астапенков",
                    "Имя": "Данила",
                    "Отчество": "Сергеевич",
                    "КраткоеОписание": "Внимание! Врач ведёт приём пациентов старше 18 лет.",
                    "Описание": "Оперирующий врач-травматолог-ортопед, врач-вертебролог. Доктор медицинских наук. Операции на позвоночнике. Эндоскопическое удаление грыж позвоночника. Стаж работы с 2002 года.",
                    "ВозрастОт": "",
                    "ВозрастДо": "",
                    "Специальность": "fe5ad46b-d136-11e5-80c0-0cc47a0dc212",
                    "ОнлайнКонсультация": "69dc81e7-d14c-11e5-80c0-0cc47a0dc212"
                },
                /*
                {
                    "Врач": "bd2fb596-dab1-11e9-80dc-a4bf0116aa73",
                    "Фамилия": "Леонтьева ",
                    "Имя": "Ольга",
                    "Отчество": "Сергеевна ",
                    "КраткоеОписание": "Внимание! Врач ведёт приём пациентов старше 18 лет.",
                    "Описание": "",
                    "ВозрастОт": "",
                    "ВозрастДо": "",
                    "Специальность": "096a0381-d137-11e5-80c0-0cc47a0dc212",
                    "ОнлайнКонсультация": "29e8d223-c573-11e9-80dc-a4bf0116aa73"
                },
                 */
                {
                    "Врач": "41d8dcf7-a6f1-11e6-80c2-0cc47a0dc212",
                    "Фамилия": "Рышков",
                    "Имя": "Владимир",
                    "Отчество": "Леонидович",
                    "КраткоеОписание": "Внимание! Врач принимает только пациенток с показаниями к оперативным вмешательствам с 18 лет!",
                    "Описание": "Оперирующий врач-акушер-гинеколог. Гистероскопия. Лапароскопические операции. Стаж работы с 1994 года.",
                    "ВозрастОт": "",
                    "ВозрастДо": "",
                    "Специальность": "9284d9d1-d136-11e5-80c0-0cc47a0dc212",
                    "ОнлайнКонсультация": "69dc8198-d14c-11e5-80c0-0cc47a0dc212"
                },
                {
                    "Врач": "b688e22a-d137-11e5-80c0-0cc47a0dc212",
                    "Фамилия": "Угай",
                    "Имя": "Андрей",
                    "Отчество": "Георгиевич",
                    "КраткоеОписание": "Внимание! Врач ведёт приём пациентов с показаниями к оперативным вмешательствам с 18 лет.",
                    "Описание": "Оперирующий врач-абдоминальный хирург. Врач высшей категории. Хирургия ожирения и сахарного диабета. Стаж работы с 1998 года.",
                    "ВозрастОт": "",
                    "ВозрастДо": "",
                    "Специальность": "4b89f2da-d137-11e5-80c0-0cc47a0dc212",
                    "ОнлайнКонсультация": "69dc8232-d14c-11e5-80c0-0cc47a0dc212"
                }
            ];

            this.setState({
                format: value,
                step: 1,
                error: false,
                doctors: data,
                doctorsLoading: false,
                docInfo: "",
                doctor: 0,
                service: 0
            });
        }

        if(value === 1.5){

            let data = [
                {
                    "Врач": "679ffdc1-e3a3-11ea-92a6-00155d0b0c14",
                    "Фамилия": "Ярушина",
                    "Имя": "Светлана",
                    "Отчество": "Витальевна",
                    "КраткоеОписание": "Внимание! Врач ведёт приём пациентов старше 18 лет.",
                    "Описание": "Реабилитолог, иглорефлексотерапевт, физиотерапевт\nСтаж 2014 года",
                    "ВозрастОт": "",
                    "ВозрастДо": "",
                    "Специальность": "e925011b-33c8-11eb-92b1-00155d650601",
                    "ОнлайнКонсультация": "00000000-0000-0000-0000-000000000000"
                }
            ];

            this.setState({
                format: value,
                step: 1,
                error: false,
                doctors: data,
                doctorsLoading: false,
                docInfo: "",
                doctor: 0,
                service: 0
            });

            //this.getServices(data[0]['Врач']);
        }

        if(value === 2){
            this.setState({
                format: value,
                step: 1,
                docInfo: "",
                doctor: 0,
                service: 0
            });
        }
    };

    onDmsChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    onDocChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let docInfo = "";

        //this.getFreeTime(value, this.state.date);

        if(this.state.format === 1){
            this.state.doctors.forEach((u,i) => {
                if(u["Врач"] == value && u["КраткоеОписание"] != "") {
                    docInfo = u["Описание"] + " " + u["КраткоеОписание"];
                }
            });

            try {
                await this.getServices(value);
            }
            catch (e) {
                console.log(e);
            }

            this.setState({
                [name]: value,
                docInfo: docInfo,
                freeTimeLoading: true,
                datesLoading: true,
                time: 0,
                times: [],
                date: false
            });
        }

        if(this.state.format === 1.5){
            this.state.doctors.forEach((u,i) => {
                if(u["Врач"] == value && u["КраткоеОписание"] != "") {
                    docInfo = u["Описание"] + " " + u["КраткоеОписание"];
                }
            });

            try {
                await this.getServices(value);
            }
            catch (e) {
                console.log(e);
            }

            this.setState({
                [name]: value,
                docInfo: docInfo,
                freeTimeLoading: true,
                datesLoading: true,
                time: 0,
                times: [],
                date: false
            });
        }

        if(this.state.format === 2){

            try {
                await this.getServices(value);
            }
            catch (e) {
                console.log(e);
            }

            this.state.doctors.forEach((u,i) => {
                if(u["Врач"] == value && u["КраткоеОписание"] != "") {
                    docInfo = u["КраткоеОписание"];
                }
            });

            this.setState({
                [name]: value,
                docInfo: docInfo,
                service: 0,
                freeTimeLoading: false,
                datesLoading: false,
                time: 0,
                times: [],
                date: false
            });
        }
    };

    onDateChange = (date) => {
        /*
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.getFreeTime(this.state.doctor, value);

        this.setState({
            [name]: value
        });
        */
        //this.toggleCalendar();
        this.getFreeTime( this.state.service, this.state.doctor, date);

        this.setState({
            date: date
        });
    };

    onServiceSelect = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let isOnline = false;
        let price = 0;
        let preparation = "";

        this.state.services.forEach((val, i, arr) => {
            //console.log(val);
            if(val["Номенклатура"] == value){
                price = val['Цена'];
                isOnline = val['Онлайн'];
                //console.log("isOnline: " + isOnline);
                preparation = val['Подготовка'];
            }
        });

        this.getFreeTime(value, this.state.doctor);

        //this.scanDates(value, this.state.doctor);

        //this.getSchedule(this.state.doctor);

        this.setState({
            service: value,
            price: price,
            preparation,
            isOnline: isOnline,
            serviceLoading: false,
            datesLoading: true,
            dates: []
        });
    };

    onTimeChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    getDoctors = async (speciality) => {
        this.setState({
            doctorsLoading: true
        });

        try {
            const r = await axios(this.state.endpoint + "getDoctors/" + speciality);
            if(r && r.data){
                this.setState({
                    error: false,
                    doctors: r.data,
                    doctorsLoading: false
                });

                if(r.data.length === 1){

                    await this.onDocChange({
                        target: {
                            name: "doctor",
                            value: r.data[0]['Врач']
                        }
                    });

                    /*this.setState({
                        doctor: r.data[0]['Врач'],
                        docInfo: r.data[0]['КраткоеОписание']
                    });*/
                }
            }
        }
        catch (e) {
            console.log(e);
        }

        /*
        axios({
            method: 'get',
            url: this.state.endpoint + "getDoctors/" + speciality
        }).then((response) => {
            let { data } = response;
            //console.log(data);
            this.setState({
                error: false,
                doctors: data,
                doctorsLoading: false
            });
        });*/
    };

    getServices = async (doc) => {

        if(doc != 0){

            if(this.state.format === 1){
                let data = [
                    {
                        "Номенклатура": "69ede093-7fc3-11ea-bafe-00155d0b0c13",
                        "Наименование": "Бесплатная консультация: «знакомство с доктором»",
                        "Часы": 0,
                        "Минуты": 15,
                        "Цена": 0,
                        "ЛичныйКабинет": true,
                        "Онлайн": true,
                        "ОнлайнПроцентСкидки": 0
                    }
                ];

                this.setState({
                    service: data[0]['Номенклатура'],
                    price: 0,
                    isOnline: true,
                    datesLoading: true,
                    dates: [],
                    error: false,
                    services: data,
                    serviceLoading: false
                });

                this.getFreeTime(data[0]['Номенклатура'], doc);
            }

            if(this.state.format === 1.5){
                let data = [
                    {
                        "Номенклатура": "994f5697-1cea-11eb-92a7-00155d0b0c14",
                        "Наименование": "Акция: Бесплатная консультация врача-физиотерапевта",
                        "Часы": 0,
                        "Минуты": 15,
                        "Цена": 0,
                        "ЛичныйКабинет": true,
                        "Онлайн": false,
                        "ОнлайнПроцентСкидки": 0
                    }
                ];
                this.setState({
                    service: data[0]['Номенклатура'],
                    price: 0,
                    isOnline: true,
                    datesLoading: true,
                    dates: [],
                    error: false,
                    services: data,
                    serviceLoading: false
                });

                this.getFreeTime(data[0]['Номенклатура'], doc);
            }

            if(this.state.format === 2){
                this.setState({
                    serviceLoading: true,
                });

                try {
                    const r = await axios(this.state.endpoint + 'getUslugi/' + doc);

                    if(r && r.data){
                        if(r.data.length === 1){

                            this.setState({
                                error: false,
                                preparation: "",
                                services: r.data,
                                serviceLoading: true,
                                datesLoading: true,
                            });

                            setTimeout( () => {
                                this.onServiceSelect({
                                    target: {
                                        name: "service",
                                        value: r.data[0]['Номенклатура']
                                    }
                                });
                            }, 500 );
                        }
                        else {
                            this.setState({
                                error: false,
                                preparation: "",
                                services: r.data,
                                serviceLoading: false
                            });
                        }
                    }
                }
                catch (e) {
                    console.log(e);

                    this.setState({
                        error: false,
                        services: [],
                        serviceLoading: false
                    });
                }

                /*
                axios({
                    method: 'get',
                    url: this.state.endpoint + 'getUslugi/' + doc,
                }).then((response) => {
                    let { data } = response;
                    //console.log(data);

                    if(data.length === 1){
                        this.getFreeTime(data[0]['Номенклатура'], doc);

                        this.setState({
                            error: false,
                            service: data[0]['Номенклатура'],
                            price: data[0]['Цена'],
                            preparation: data[0]['Подготовка'],
                            isOnline: data[0]['Онлайн'],
                            datesLoading: true,
                            dates: [],
                            services: data,
                            serviceLoading: false
                        });
                    }
                    else {
                        this.setState({
                            error: false,
                            preparation: "",
                            services: data,
                            serviceLoading: false
                        });
                    }
                }).catch( err => {
                    this.setState({
                        error: false,
                        services: [],
                        serviceLoading: false
                    });
                });*/
            }
        }
    };

    getSchedule = (doc) => {
        if(doc != 0){

            this.setState({
                scheduleLoading: true,
            });

            axios({
                method: 'get',
                url: this.state.endpoint + 'getSchedule/' + doc,
            }).then((response) => {
                let { data } = response;
                //console.log(data);
                let availableDates = [];

                if(data.length > 0){
                    data.forEach(function (val, i) {
                        availableDates.push(new Date(val['Дата']));
                    });
                }

                if(availableDates.length > 0){

                    //берем первую дату из массива и ставим в дейтпикер по дефолту
                    let res = [...availableDates];
                    let firstDate = res.shift();

                    this.setState({
                        error: false,
                        dates: availableDates,
                        date: firstDate,
                        highlightDates: [{ "react-datepicker__day--highlighted": availableDates }],
                        datesLoading: false
                    });

                    //запрашиваем для дефолтной даты времена приема
                    this.getFreeTime( this.state.service, this.state.doctor, firstDate);
                }
            });
        }
    };

    scanDate = async (service, doctor, date) => {
        try {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            if(day < 10) day = '0'+day;
            if(month < 10) month = '0'+month;

            const response = await axios({
                method: 'get',
                url: this.state.endpoint + 'getFreeTime/' + doctor + '/' + service + '/' + year + '-' + month + '-' + day + "T00:00:00",
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    scanDates = async (service, doctor) => {

        if(service != 0 && doctor != 0){
            let availableDates = [];
            let total = 0;

            this.setState({
                freeTimeLoading: false,
                time: 0
            });

            for(let k=1; k < 15; k++){
                let date = new Date();
                date.setDate(date.getDate() + k);

                let data = await this.scanDate(service, doctor, date);

                this.setState({progress: k*7 + '%'});

                if(data.length > 0 ){
                    availableDates.push(date);
                }


                /*
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();

                if(day < 10) day = '0'+day;
                if(month < 10) month = '0'+month;

                axios({
                    method: 'get',
                    url: this.state.endpoint + 'getFreeTime/' + doctor + '/' + service + '/' + year + '-' + month + '-' + day + "T00:00:00",
                }).then((response) => {
                    let { data } = response;

                    if(data.length > 0 ){
                        availableDates.push(date);
                    }

                    total++;

                    if(total==21){
                        if(availableDates.length > 0){
                            //сортируем полученный массив по датам по возрастанию
                            availableDates.sort(function (a,b) {
                                return (a - b);
                            });

                            //берем первую дату из массива и ставим в дейтпикер по дефолту
                            let res = [...availableDates];
                            let firstDate = res.shift();

                            this.setState({
                                error: false,
                                dates: availableDates,
                                date: firstDate,
                                highlightDates: [{ "react-datepicker__day--highlighted": availableDates }],
                                freeTimeLoading: false
                            });

                            //запрашиваем для дефолтной даты времена приема
                            this.getFreeTime( this.state.service, this.state.doctor, firstDate);
                        }
                        else{
                            this.setState({
                                error: false,
                                dates: [],
                                times: [],
                                date: false,
                                highlightDates: [],
                                freeTimeLoading: false
                            });
                        }
                    }
                });*/
            }

            if(availableDates.length > 0){
                //сортируем полученный массив по датам по возрастанию
                availableDates.sort(function (a,b) {
                    return (a - b);
                });

                //берем первую дату из массива и ставим в дейтпикер по дефолту
                let res = [...availableDates];
                let firstDate = res.shift();

                this.setState({
                    error: false,
                    dates: availableDates,
                    date: firstDate,
                    highlightDates: [{ "react-datepicker__day--highlighted": availableDates }],
                    datesLoading: false
                });

                //запрашиваем для дефолтной даты времена приема
                this.getFreeTime( this.state.service, this.state.doctor, firstDate);
            }
            else{
                this.setState({
                    error: false,
                    dates: [],
                    times: [],
                    date: false,
                    highlightDates: [],
                    datesLoading: false
                });
            }
        }
    };

    getFreeTime = (service, doctor) => {
        //console.log("service: " + service);
        //console.log("doctor: " + doctor);

        if(service != 0 && doctor != 0){
            axios({
                method: 'get',
                url: this.state.endpoint + 'getFreeTime/' + doctor + '/' + service + '/'
            }).then((response) => {
                let { data } = response;

                let times = [];
                let dates = [];
                let d = '';

                if(Array.isArray(data)){
                    data.forEach((v,i,arr) => {
                        d = v['ПериодС'].split('T');
                        dates.push(d[0]);
                        times.push(v['ПериодС']);
                    });

                    let uniqueDates = [...new Set(dates)];
                    uniqueDates.forEach((date, i) => {
                        let daytimes = [];
                        times.forEach((time, k) => {
                            if(time.indexOf(date) >= 0) {
                                daytimes.push(time.substr(11,5));
                            }
                        });

                        uniqueDates[i] = {
                            date: date,
                            times: daytimes,
                            humanDate: date.substr(8,2) + "." + date.substr(5,2) //+ "." + date.substr(0,4)
                        };
                    });

                    this.setState({
                        error: false,
                        dates: uniqueDates,
                        datesLoading: false
                    });
                }
                else {
                    this.setState({
                        error: false,
                        dates: [],
                        datesLoading: false
                    });
                }
            }).catch( err => {
                this.setState({
                    error: false,
                    dates: [],
                    datesLoading: false
                });
            } );
        }
    };

    getFreeTime1 = (service, doctor, date) => {
        if(date && service != 0 && doctor != 0){
            this.setState({
                freeTimeLoading: true,
                time: 0
            });

            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            if(day < 10) day = '0'+day;
            if(month < 10) month = '0'+month;

            axios({
                method: 'get',
                url: this.state.endpoint + 'getFreeTime/' + doctor + '/' + service + '/' + year + '-' + month + '-' + day + "T00:00:00",
            }).then((response) => {
                let { data } = response;

                this.setState({
                    error: false,
                    times: data,
                    freeTimeLoading: false
                });
            });
        }
    };

    renderSpeciality = () => {
        if(this.state.specialitiesLoading)
            return ( <div className="form-group shadow">
                <label htmlFor="specialitySelect"><b>Специальность</b></label>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-light mx-auto" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>);

        if(this.state.specialities.length > 0 && !this.state.specialitiesLoading)
            return (
                <div className="form-group shadow d-none1">
                    <label htmlFor="specialitySelect"><b>Специальность</b></label>
                    <select className="form-control" id="specialitySelect" name={"speciality"} value={this.state.speciality} onChange={this.onSpecChange}>
                        <option key={0} value={0}>Выберите специальность</option>
                        {
                            this.state.specialities.map(function (s, i) {
                                return (
                                    <option key={i} value={s['Специальность']}>{s["Представление"]}</option>
                                )
                            })
                        }
                    </select>
                </div>
            );

        if(this.state.specialities.length == 0 && !this.state.specialitiesLoading)
            return (
                <div className="form-group shadow">
                    <label htmlFor="specialitySelect"><b>Специальность</b></label>
                    <div className="alert alert-danger" role="alert">
                        Не удалось получить список специальностей.
                    </div>
                </div>
            );
    };

    sendTo1C = () => {

        window.dataLayer.push({'event': 'online_request'});

        let roiLabel = window.roistat ? window.roistat.getVisit() : "online-zapis";

        //console.log(roiLabel);

        this.setState({
            sendLoading: true
        });

        let data =
            "name=" + this.state.name.trim() +
            "&lastname=" + this.state.lastname.trim() +
            "&secondname=" + this.state.secondname.trim() +
            "&birthdate=" + this.state.birthdate.trim() +
            "&phone=" + this.state.phone.trim() +
            "&date=" + this.state.time +
            "&doc=" + this.state.doctor +
            "&service=" + this.state.service +
            "&dms=" + this.state.dms +
            "&isOnline=" + this.state.isOnline +
            "&roistat=" + roiLabel
        ;

        axios({
            method: 'post',
            url: this.state.endpoint + "setZapis",
            data: data
        }).then((response) => {
            let { data } = response;

            if(data.success) {
                this.setState({
                    sendError: false,
                    sendLoading: false,
                    sendSuccess: true,
                    docId: data.doc,
                    tip: data.tip,
                    step: 3
                });
            }
            else {
                this.setState({
                    sendError: true,
                    errType: data.errType,
                    sendLoading: false,
                    sendSuccess: false,
                    step: 2
                });
            }
        });
    };

    onTimeSelect = (date,day,time) => {
        this.setState({
            time: date,
            step: 2,
            selectedDay: day,
            selectedTime: time
        });

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    cancelZapis = () => {
        this.setState({
            sendLoading: true
        });

        let data = "doc=" + this.state.docId;

        axios({
            method: 'post',
            url: this.state.endpoint + "cancelZapis",
            data: data
        }).then((response) => {
            let { data } = response;

            if(data.success) {
                this.setState({
                    sendError: false,
                    sendLoading: false,
                    sendSuccess: true,
                    cancelSuccess: true,
                    docId: ""
                });
            }
            else {
                this.setState({
                    sendError: true,
                    sendLoading: false,
                    sendSuccess: false,
                    cancelSuccess: false
                });
            }
        });
    };

    renderDoctorList = () => {

        if(this.state.speciality != 0){
            if(this.state.doctorsLoading)
                return ( <div className="form-group shadow">
                    <label htmlFor="specialitySelect"><b>Врачи с выбранной специальностью</b></label>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-light mx-auto" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>);

            if(this.state.doctors.length > 0 && !this.state.doctorsLoading)
                return (
                    <div className="form-group shadow">
                        <label htmlFor="doctorSelect"><b>Врачи с выбранной специальностью</b></label>
                        <select className="form-control" id="doctorSelect" name={"doctor"} value={this.state.doctor} onChange={this.onDocChange}>
                            <option key={0} value={0}>Выберите врача-специалиста</option>
                            {
                                this.state.doctors.map(function (s, i) {
                                    //console.log(s);
                                    return (
                                        <option
                                            key={i}
                                            value={s["Врач"]}
                                        >
                                            {s["Фамилия"]} {s["Имя"]} {s["Отчество"]}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                );

            if(this.state.doctors.length == 0 && !this.state.doctorsLoading)
                return (
                    <div className="form-group shadow">
                        <label htmlFor="specialitySelect"><b>Врачи с выбранной специальностью</b></label>
                        <div className="alert alert-danger" role="alert">
                            Не удалось получить список врачей.
                        </div>
                    </div>
                );
        }

        if(this.state.format == 1){
            return (
                <div className="form-group shadow">
                    <label htmlFor="doctorSelect"><b>Врач-специалист</b></label>
                    <select className="form-control" id="doctorSelect" name={"doctor"} value={this.state.doctor} onChange={this.onDocChange}>
                        <option key={0} value={0}>Выберите врача-специалиста</option>
                        {
                            this.state.doctors.map(function (s, i) {
                                //console.log(s);
                                return (
                                    <option
                                        key={i}
                                        value={s["Врач"]}
                                    >
                                        {s["Фамилия"]} {s["Имя"]} {s["Отчество"]}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            );
        }

        if(this.state.format == 1.5){
            return (
                <div className="form-group shadow">
                    <label htmlFor="doctorSelect"><b>Врач-специалист</b></label>
                    <select className="form-control" id="doctorSelect" name={"doctor"} value={this.state.doctor} onChange={this.onDocChange}>
                        <option key={0} value={0}>Выберите врача-специалиста</option>
                        {
                            this.state.doctors.map(function (s, i) {
                                //console.log(s);
                                return (
                                    <option
                                        key={i}
                                        value={s["Врач"]}
                                    >
                                        {s["Фамилия"]} {s["Имя"]} {s["Отчество"]}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            );
        }

        return null;
    };

    renderServiceList = () => {

        if(this.state.doctor != 0 && this.state.format == 2){
            if(this.state.serviceLoading)
                return ( <div className="form-group shadow">
                    <label htmlFor="specialitySelect"><b>Услуги</b></label>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border mx-auto" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>);

            if(this.state.services.length === 1 && !this.state.serviceLoading && typeof this.state.services == "object")
                return (
                    <div className="form-group shadow">
                        <label htmlFor="timeSelect"><b>Услуга</b></label>
                        <div>
                            {this.state.services[0]['Наименование']}
                        </div>
                    </div>
                );

            if(this.state.services.length > 0 && !this.state.serviceLoading && typeof this.state.services == "object")
                return (
                    <div className="form-group shadow">
                        <label htmlFor="timeSelect"><b>Услуга</b></label>
                        <select className="form-control" id="serviceSelect" name={"service"} value={this.state.service} onChange={this.onServiceSelect}>
                            <option key={0} value={0}>Выберите услугу</option>
                            {
                                this.state.services.map(function (s, i) {
                                    return (
                                        <option key={i} value={s["Номенклатура"]}>{s["Наименование"]}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                );

            if(this.state.services.length == 0 || !this.state.serviceLoading || typeof this.state.services == "string")
                return (
                    <div className="form-group shadow">
                        <label htmlFor="specialitySelect"><b>Услуги</b></label>
                        <div className="alert alert-danger" role="alert">
                            Не удалось получить список услуг выбранного специалиста.
                        </div>
                    </div>
                );
        }

        return null;

    };

    renderPrice = () => {

        if(this.state.service != 0){
            return (
                <div className="form-group shadow">
                    <label className={"mb-0"}><b>Стоимость: {this.state.price} рублей</b></label>
                </div>
            );
        }
        else return null;

    };

    toggleCalendar = (e) => {
        //e && e.preventDefault();
        this.setState({isOpen: !this.state.isOpen})
    };

    renderDateSelect = () => {

        if(this.state.datesLoading){
            return (
                <div className={"col-12"}>
                    <div className="form-group shadow">
                        <label htmlFor="specialitySelect">Получаем информацию о датах приёма</label>
                        <div className="progress border border-white">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                                 aria-valuenow={"0"} aria-valuemin={"0"} aria-valuemax={"100"} style={{width: this.state.progress}} >
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if(this.state.doctor != 0 && this.state.service != 0 && this.state.date && !this.state.datesLoading ){
            let date;
            let day = this.state.date.getDate();
            if(this.state.date.getDate() < 10) day = "0" + this.state.date.getDate();

            let month = this.state.date.getMonth() + 1;
            if(month < 10) month = "0" + month;
            date = day + "." + month + "." + this.state.date.getFullYear();

            return (
                <div className={"col-12 col-lg-6"}>
                    <div className="form-group shadow">
                        <label>Дата приёма</label>
                        <div className={"d-flex justify-content-between"}>
                            <div
                                className={"form-control mr-3"}
                                onClick={this.toggleCalendar}
                            >
                                {date}
                            </div>
                            <button
                                className="btn btn-outline-light"
                                onClick={this.toggleCalendar}
                            >
                                Изменить
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if(this.state.doctor != 0 && this.state.service != 0 && !this.state.date && !this.state.datesLoading ){
                return (
                    <div className={"col-12"}>
                        <div className="form-group shadow">
                            <label htmlFor="specialitySelect"><b>Дата приёма</b></label>
                            <div className="alert alert-danger" role="alert">
                                Нет свободного времени для приёма.
                            </div>
                        </div>
                    </div>
                );
        }
        else return null;
    };

    renderPreparation = () => {

        if(this.state.preparation){
            return (
                <div className="form-group shadow bg-danger text-white">
                    <label className={"mb-0"}>
                        <i className="fas fa-exclamation-circle mr-2"></i>
                        <b>Информация о подготовке</b>
                    </label>
                    <div>
                        { this.state.preparation }
                    </div>
                </div>
            );
        }

        return null;
    };

    renderPicker = () => {

        if(this.state.isOpen){
            return (
                    <DatePicker
                        dateFormat="dd.MM.yyyy"
                        selected={this.state.date}
                        withPortal
                        locale={ru}
                        inline
                        highlightDates={this.state.highlightDates}
                        includeDates={this.state.dates}
                        onChange={this.onDateChange}
                        onSelect={this.toggleCalendar}
                        todayButton={"Сегодня"}
                    />
            );
        }
        else return null;

        /*
        highlightDates={this.state.highlightDates}
                        includeDates={this.state.dates}
         */
    };

    renderTimesList = () => {

        if(this.state.doctor != 0 && this.state.date != "" && this.state.service != 0){
            if(this.state.freeTimeLoading)
                return (
                    <div className={"col-12 col-lg-6"}>
                        <div className="form-group">
                            <label htmlFor="specialitySelect">Свободное время</label>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-light mx-auto" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            if(this.state.times.length > 0 && !this.state.freeTimeLoading && typeof this.state.times == "object")
                return (
                    <div className={"col-12 col-lg-6"}>
                        <div className="form-group">
                            <label htmlFor="timeSelect">Свободное время</label>
                            <select className="form-control" id="timeSelect" name={"time"} value={this.state.time} onChange={this.onTimeChange}>
                                <option key={0} value={0}>Выберите время</option>
                                {
                                    this.state.times.map(function (s, i) {
                                        return (
                                            <option key={i} value={s["ПериодС"]}>{s["ПериодС"].substring(11,16)}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                );

            if(this.state.times.length == 0 || !this.state.freeTimeLoading || typeof this.state.times == "string")
                return (
                    <div className={"col-12 col-lg-6"}>
                        <div className="form-group">
                            <label htmlFor="specialitySelect"><b>Свободное время</b></label>
                            <div className="alert alert-danger" role="alert">
                                Нет свободного времени для приёма.
                            </div>
                        </div>
                    </div>
                );
        }
        else return null;
    };

    renderZapisBtn = () => {
        //console.log(this.state.time);

        if(this.state.time != 0) return (
            <div className={"form-group"}>
                <button
                    type="button"
                    className="btn btn-light btn-lg mt-4"
                    onClick={ () => this.setState({step: 2}) }
                >
                    Записаться
                </button>
            </div>
        );
        else return null;
    };

    renderSubmitBtn = () => {
        if(this.state.step == 2){
            if(this.state.sendLoading){
                return (
                    <div className={""}>
                        <button
                            type="button"
                            className="btn btn-light btn-lg mt-4 d-flex  mx-auto"
                            disabled
                        >
                            <span className="spinner-border mr-3" role="status" aria-hidden="true"></span>
                            Отправка...
                        </button>
                    </div>
                );
            }

            if(this.state.name != "" && this.state.lastname != "" && this.state.phone.length >= 7 && !this.state.sendSuccess && this.state.check && this.state.birthdate != "") return (
                <div className={""}>
                    <button
                        type="button"
                        className="btn btn-light btn-lg mt-4 d-flex mx-auto"
                        onClick={this.sendTo1C}
                    >
                        Отправить
                    </button>
                </div>
            );
            else return (
                <div className={""}>
                    <button
                        type="button"
                        className="btn btn-light btn-lg mt-4 d-flex mx-auto"
                        disabled
                    >
                        Отправить
                    </button>
                </div>
            );
        }
    };

    renderTimeSlider = () => {
        if(this.state.doctor != 0 && this.state.service != 0){
            if(this.state.datesLoading)
                return (
                    <div className="form-group">
                        <label><b>Даты и время приёма</b></label>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-light mx-auto" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                );

            if(!this.state.datesLoading && this.state.dates.length > 0)
                return (
                    <div className="form-group">
                        <label><b>Даты и время приёма</b></label>
                        <p>Нажмите на кнопку с подходящим для Вас временем.</p>
                        <TimeSlider
                            dates={this.state.dates}
                            onTimeSelect={this.onTimeSelect}
                        />
                    </div>
                );

            if(!this.state.datesLoading && this.state.dates.length == 0)
                return (
                    <div className="form-group">
                        <label><b>Даты и время приёма</b></label>
                        <p>
                            Нет свободного времени для приёма.
                        </p>
                    </div>
                );
        }
    };

    formatSelector = () => {
        return (
            <div className="form-group shadow">
                <label htmlFor="specialitySelect"><b>Формат консультации</b></label>
                <select className="form-control" name={"format"} value={this.state.format} onChange={this.onFormatChange}>
                    <option key={0} value={0}>Выберите формат</option>
                    <option key={1.5} value={1.5}>Реабилитация после COVID-19</option>
                    <option key={2} value={2}>Запись на приём к врачу</option>
                </select>
            </div>
        )
    };

    render()
    {
        if(this.state.step == 1) {

            let specialitySelect = this.renderSpeciality();

            let doctorSelect = this.renderDoctorList();

            let serviceSelect = this.renderServiceList();

            let preparation = this.renderPreparation();

            let price = this.renderPrice();

            let dateSelect = this.renderDateSelect();

            let timeSelect = this.renderTimesList();

            //let btn = this.renderZapisBtn();
            let btn = null;

            let picker = this.renderPicker();

            let timeSlider = this.renderTimeSlider();

            let mobileAppInfo = (
                <div className="form-group shadow">
                    <div className="text-right">
                        <button className="btn" onClick={ () => this.setState({ showMobileAppInfo: false }) }>
                            <i className="fas fa-times text-secondary"></i>
                        </button>
                    </div>
                    <h5 className="text-center">
                        Уважаемые пациенты, обратите внимание!
                    </h5>
                    {
                        (this.state.format === 1) &&
                        <p>
                            Услуга "Знакомство с доктором" позволяет вам получить рекомендации по вопросам необходимой диагностики, план обследования, направление к другому врачу-специалисту. При выборе данной услуги врач-специалист не ставит диагноз, не назначает лечение.
                        </p>
                    }
                    {
                        (this.state.format === 1.5) &&
                        <p>
                            Первичная консультация по реабилитации после Covid-19 является бесплатной. На консультации врач проводит осмотр пациента, оценивает состояние организма, а также может назначить дополнительные обследования, если они необходимы.
                        </p>
                    }
                </div>
            );

            /*
            window.scrollTo({
                top: 2000,
                behavior: "smooth"
            });
            */

            return (
                <div className="">

                    { (this.state.format == 1 && this.state.showMobileAppInfo ) && mobileAppInfo }

                    { (this.state.format == 1.5 && this.state.showMobileAppInfo ) && mobileAppInfo }

                    { (this.state.format == 2) && specialitySelect }

                    {doctorSelect}

                    {
                        (this.state.docInfo == "") ? null : (
                            <div className="form-group shadow">
                                <label>
                                    <b>Информация о враче-специалисте</b>
                                </label>
                                <div>
                                    {this.state.docInfo}
                                </div>
                            </div>
                        )
                    }
                    {serviceSelect}

                    {preparation}

                    {price}
                    <div className={"d-flex align-items-end justify-content-center"}>
                        {btn}
                    </div>
                    {picker}
                    {timeSlider}
                </div>
            );
        }

        if(this.state.step == 2) {
            let btn = this.renderSubmitBtn();

            return (
                <div>
                    <div style={{marginBottom: "1rem"}}>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={()=>this.setState({step: 1})}
                        >
                            <i className="fas fa-arrow-left mr-2"></i>
                            Назад
                        </button>
                    </div>
                    <div className="form-group">
                        <h5 className={"text-center mb-0"}>Запись на приём на {this.state.selectedDay} в {this.state.selectedTime} </h5>
                    </div>
                    <div className="form-group">
                        <h5 className={"text-center mb-3"}>Укажите свои контактные данные</h5>
                        <div className={"mb-3"}>
                            <label><b>Фамилия</b></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Фамилия"
                                value={this.state.lastname}
                                onChange={(e)=> {
                                    let val = e.target.value.replace(/[^ЁёА-я]/giu, "");
                                    val = val.toLowerCase();
                                    let res = val.substring(0,1).toUpperCase() + val.substring(1);
                                    this.setState({lastname: res})}
                                }
                                required
                            />
                        </div>

                        <div className={"mb-3"}>
                            <label><b>Имя</b></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Имя"
                                value={this.state.name}
                                onChange={(e)=> {
                                    let val = e.target.value.replace(/[^ЁёА-я]/giu, "");
                                    val = val.toLowerCase();
                                    let res = val.substring(0,1).toUpperCase() + val.substring(1);
                                    this.setState({name: res})}
                                }
                                required
                            />
                        </div>

                        <div className={"mb-3"}>
                            <label><b>Отчество</b></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Отчество"
                                value={this.state.secondname}
                                onChange={(e)=> {
                                    let val = e.target.value.replace(/[^ЁёА-я]/giu, "");
                                    val = val.toLowerCase();
                                    let res = val.substring(0,1).toUpperCase() + val.substring(1);
                                    this.setState({secondname: res})}
                                }
                                required
                            />
                        </div>

                        <div className={"mb-3"}>
                            <label><b>Телефон</b></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Телефон"
                                maxLength={11}
                                value={this.state.phone}
                                onChange={(e)=>this.setState({phone: e.target.value.replace(/\D+/gi, "")})}
                                required
                            />
                        </div>

                        <div className={"row"}>
                            <div className={"mb-3 col-12 col-md-6"}>
                                <label><b>Дата рождения</b></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    max={this.state.currentDate}
                                    placeholder="Дата рождения"
                                    value={this.state.birthdate}
                                    onChange={(e)=>this.setState({birthdate: e.target.value})}
                                    required
                                />
                            </div>

                            <div className={"mb-3 col-12 col-md-6"}>
                                <div className={"d-none"}>
                                    <label htmlFor="specialitySelect"><b>По полису ДМС</b></label>
                                    <select className="form-control" id="dmsSelect" name={"dms"} value={this.state.dms} onChange={this.onDmsChange}>
                                        <option value={false}>Нет</option>
                                        <option value={true}>Да</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className={"form-check"}>
                            <input type="checkbox" className="form-check-input" id="check1" checked={this.state.check} onChange={ (e) => this.setState({check: !this.state.check }) } />
                            <label className="form-check-label" htmlFor="check1" style={{ userSelect: 'none' }}>
                                Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в <a data-toggle="modal" data-target="#exampleModal" className={"text-dark"} style={{textDecoration: 'underline'}} href={"#"} >Согласии на обработку персональных данных</a>.
                            </label>
                        </div>
                    </div>

                    {btn}

                    {
                        this.state.sendError ? (
                            <div>
                                <div className="alert alert-danger mt-3" role="alert">
                                    <div className={"d-flex justify-content-start"}>
                                        <div className={"d-flex align-items-center mr-3"}>
                                            <i className="fas fa-exclamation-circle fa-3x"></i>
                                        </div>
                                        <div>
                                            <h5>Не удалось оформить запись.</h5>
                                            <p>Попробуйте ещё раз позднее или позвоните по телефону <a href="tel:+73512004800">8 (351) 200-48-00</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-group text-center"}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-dark my-2"
                                        onClick={ () => this.setState({
                                            step: 1,
                                            speciality: false,
                                            doctors: [],
                                            dates: [],
                                            doctor: false,
                                            date: new Date(),
                                            time: 0
                                            })
                                        }
                                    >
                                        <i className="fas fa-arrow-left mr-3"></i>
                                        Вернуться к выбору времени
                                    </button>
                                </div>
                            </div>

                        ) : null
                    }
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-dark" id="exampleModalLabel">Согласие на обработку персональных данных</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-dark">
                                    <p>Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных ООО МЦ "МЕДЕОР" (ОГРН 1157456003814, ИНН 7452123748), зарегистрированным в соответствии с законодательством РФ по адресу:
                                    УЛ. ГОРЬКОГО, ДОМ 16, ОФИС 328, 454071 ЧЕЛЯБИНСК ЧЕЛЯБИНСКАЯ ОБЛАСТЬ, Россия (далее по тексту - Оператор).
                                    Персональные данные - любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.</p>
                                    <p>Настоящее Согласие выдано мною на обработку следующих персональных данных:
                                        <br/>- Имя;
                                        <br/>- Фамилия;
                                        <br/>- Телефон;
                                        <br/>- E-mail;
                                        <br/>- Комментарий.
                                    </p>

                                    <p>Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.</p>
                                    <p>Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:
                                        <br/>- предоставление мне услуг/работ;
                                        <br/>- направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;
                                        <br/>- подготовка и направление ответов на мои запросы;
                                        <br/>- направление в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора.
                                    </p>

                                    <p>Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес medeor-med@mail.ru. В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.06.2006 г.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if(this.state.step == 3) {
            return(
                <div>
                    {
                        (this.state.sendSuccess && !this.state.cancelSuccess) ? (
                            <div>
                                <div className="alert bg-white mt-5" role="alert">
                                    <div className={"d-flex justify-content-start"}>
                                        <div className={"d-flex align-items-center mr-3"}>
                                            <i className="far fa-check-circle fa-3x"></i>
                                        </div>
                                        <div className={"d-flex flex-column justify-content-center"}>
                                            <h5>Ваша запись оформлена</h5>
                                            <h5>Благодарим Вас за обращение!</h5>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (this.state.tip.length > 0) ? (
                                        <div className="d-none alert bg-white mt-3" role="alert">
                                            <div className={"d-flex justify-content-start"}>
                                                <div className={"d-flex align-items-center mr-3"}>
                                                    <i className="fas fa-info-circle fa-2x"></i>
                                                </div>
                                                <div className={"d-flex flex-column justify-content-center"}>
                                                    <p className={"mb-0"}>
                                                        {this.state.tip}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                <div className={"d-flex justify-content-center"}>
                                    <button
                                        className={"btn btn-danger btn-lg my-3"}
                                        type="button"
                                        onClick={ () => window.location.reload() }
                                    >
                                        В начало
                                    </button>
                                </div>

                                <div className={"d-flex1 d-none justify-content-center"}>
                                    <button
                                        className={"btn btn-danger btn-lg my-3"}
                                        type="button"
                                        onClick={this.cancelZapis}
                                    >
                                        Отменить запись
                                    </button>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        (this.state.sendSuccess && this.state.cancelSuccess) ? (
                            <div>
                                <div className="alert bg-white mt-5" role="alert">
                                    <div className={"d-flex justify-content-start"}>
                                        <div className={"d-flex align-items-center mr-3"}>
                                            <i className="far fa-check-circle fa-3x"></i>
                                        </div>
                                        <div className={"d-flex justify-content-center"}>
                                            <h5>Ваша запись отменена</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            );
        }

        if(this.state.step == 10) {
            return (
                <div>
                    10
                </div>
            );
        }
    }
}