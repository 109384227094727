import React from "react";
import Slider from "react-slick";

export default class TimeSlider extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            date: false,
            time: false,
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    makeZapis = (date, day, time) => {
        //console.log(date + "T" + time + ":00");
        this.props.onTimeSelect(date + "T" + time + ":00", day, time);
    };

    render() {
        if(this.props.dates.length == 0){
            return null;
        }
        else{
            let settings = {
                dots: true,
                infinite: false,
                centerMode: true,
                centerPadding: "60px",
                slidesToShow: 1,
                adaptiveHeight: true,
                speed: 500,
                slidesToScroll: 1,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            };

            let settings1;

            if(this.props.dates.length > 0 && this.props.dates.length <= 3 ){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    slidesToShow: this.props.dates.length,
                    slidesToScroll: 1,
                    centerPadding: "10px"
                };
            }

            if(this.props.dates.length > 3){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    responsive: [
                        {
                            breakpoint: 7680,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerPadding: "50px"
                            }
                        },
                        {
                            breakpoint: 425,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 320,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerPadding: "15px"
                            }
                        }
                    ]
                };
            }



            let settings2 = {
                dots: false,
                infinite: true,
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                speed: 500,
                slidesToScroll: 1,
                adaptiveHeight: false
            };

            let ddd = [...this.props.dates];

            let days = ddd.map((u,i) => {
                    return (
                        <div key={i} className={"px-3"}>
                            <button type="button" className="btn btn-outline-dark nav1 px-2 dayBtn">
                                {u.humanDate}
                            </button>
                        </div>
                    );
                });

            let dates = this.props.dates.map((u,i) => {
                let times = u.times.map((time,j) => {
                    return (
                        <div className={"col-4 col-sm-3 text-center p-2 "} key={j}>
                            <button
                                type="button"
                                className="btn btn-dark my-1 w-100 timeBtn"
                                onClick={() => this.makeZapis(u.date, u.humanDate, time)}
                            >
                                {time}
                            </button>
                        </div>
                    );
                });

                return (
                    <div key={i} className={"py-3 px-0 px-sm-3 mt-3"}>
                        <div className={""}>
                            <p className={"text-center mb-0"}><b>Время приёма {u.humanDate}</b></p>
                            <div className={"row px-0 mx-0"}>
                                {times}
                            </div>
                        </div>
                    </div>
                );
            });

            return (
                <div>
                    <p className={"text-center"}>
                        <b>Дата приёма</b>
                    </p>
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...settings1}
                    >
                        {days}
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </Slider>

                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        {...settings2}
                    >
                        {dates}
                    </Slider>
                </div>
            );
        }
    }
}

/*

 */