import React, { Component } from 'react';
import ZapisScreen from './components/zapis/ZapisScreen';

class App extends Component {
    render() {
        return (
            <ZapisScreen />
        );
    }
}

export default App;