import React from 'react';
import ZapisForm from "./ZapisForm";

export default class ZapisScreen extends React.Component
{
    constructor()
    {
        super(...arguments);
    }

    render()
    {
        return (
            <div className="container d-flex flex-column justify-content-center align-items-center">
                    <div className={"col-12 px-0 px-lg-3"}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <a href="https://medeor74.ru">
                                <img className="img-fluid mt-3"  src="https://medeor74.ru/wp-content/uploads/2017/11/logo-dark.png" />
                            </a>
                        </div>

                        <h4 className={"text-center mt-3 mt-lg-3 mb-3"}>Онлайн-запись на приём</h4>

                        <div className="jumbotron shadow-sm bgg">
                            <ZapisForm />
                        </div>

                        <div className="form-group mb-0 shadow-sm jumbotron p-3 border mb-4 text-center">
                            Уважаемые пациенты! Запись на услуги по полису ДМС осуществляется только через колл-центр по телефону:
                            <br/>
                            <a className="ml-2 text-primary" href="tel:+73512778808"><b>+7 (351) 277-88-08</b></a>
                        </div>

                        <div className="d-none text-center p-3 shadow-sm bg-danger text-white mb-4 rounded-lg">
                            <p>
                                С 30.10.2021 лица старше 60 лет могут посещать медицинский центр только при наличии QR-кода.
                            </p>
                            <p className="mb-0">
                                Подробности по телефону<br/><a className="text-white" href="tel:+73512778808"><b>+7 (351) 277-88-08</b></a>
                            </p>
                        </div>

                        <div className="form-group mb-0 shadow-sm jumbotron p-3 border mb-4 text-center">
                            <p className={""}>
                                Вы уже были на очном приёме у врача-специалиста и вам требуется скорректировать план лечения или получить дополнительную консультацию?
                            </p>
                            <p className={""}>
                                <b className="">
                                    Вы можете сделать это в онлайн-формате.
                                </b>
                            </p>
                            <p className={""}>
                                Просто скачайте приложение "МЦ Медеор" в App Store или Google Play.
                            </p>
                            <div className="text-center">
                                <a className="btn btn-outline-primary" href="https://onelink.to/zqp4rf" target="_blank" >
                                    Скачать приложение
                                </a>
                            </div>
                        </div>

                        <div className="d-none d-lg-block form-group mb-0 shadow-sm jumbotron p-3 border mb-4 text-center">
                            <img src="qr.png" alt="" width="150" height="150" />

                            <div className="text-center mt-4">
                                Отсканируйте QR-код,<br/>чтобы скачать мобильное приложение
                            </div>
                        </div>

                    </div>
            </div>
        );
    }
}